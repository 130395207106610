import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <div className="container">
      <main className="section is-centered">
        <title>Not found</title>
        <h1 className="title">Page non trouvée</h1>
        <p className="content">
          Désolé
          <span className="icon" role="img" aria-label="Pensive emoji">
            😔
          </span>
          nous ne pouvons pas trouver cette page.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code className="code">src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link className="button is-link" to="/">
            Retour à l'acceuil
          </Link>
        </p>
      </main>
    </div>
  );
};

export default NotFoundPage;
